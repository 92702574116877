<template>
  <div class="h5Render">
    <v-form-render-h5 :form-json="{}" :form-data="{}" :loading="loading" :option-data="optionData" ref="vformDesigner" v-if="ready" @onSaveDraft="saveDraft" @onFormData="saveFormData" @onFormDataError="saveFormDataError" @onFormDataChanged="formDataChanged">
      <template v-slot:myVxeTable="{
          fields,
          datas,
          isAdmin,
          field,
          fieldRef,
          viewItem,
          removeItem,
          disabled,
          enableSearch = true,
          enableFilter = true,
        }">
        <fill-select-table-view :fields="fields" :datas="datas" :field="field" :fieldRef="fieldRef" :isAdmin="isAdmin" :viewItem="viewItem" :removeItem="removeItem" :onHandlePreviewImg="onHandlePreviewImg" :formOptionData="optionData" :disabled="disabled" :enableSearch="enableSearch"
                                :enableFilter="enableFilter" :formDetail="formJson" :formData="formData"></fill-select-table-view>
      </template>
    </v-form-render-h5>
    <!-- <el-button type="default" @click="postMsg">消息</el-button> -->

    <!--填报成功，公众号引导提示-->
    <el-dialog :visible.sync="fillSuccessShow" width="95%" append-to-body @close="onCloseFillSuccessPop">
      <!-- <el-dialog :visible="true" width="340px" append-to-body @close="onCloseFillSuccessPop"> -->
      <div style="display: flex; display: -webkit-flex; flex-direction: column">
        <h1 style="
            display: flex;
            display: -webkit-flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            margin: -20px 0 0;
          ">
          <i class="el-icon-check" style="color: #67c23a; font-size: 40px; margin-right: 10px"></i>提交成功！
        </h1>
        <!-- 表单未开启支付，显示提交成功提示 -->
        <!-- 默认提交文字 -->
        <template v-if="customTip.commitTipShow == 0">
          <p style="
              width: 100%;
              text-align: left;
              font-size: 14px;
              margin: 0;
              line-height: 30px;
            ">
            若希望及时接受相关通知，可关注我们的公众号“鲸鱼填报”
          </p>
          <p style="
              width: 100%;
              text-align: left;
              font-size: 14px;
              margin: 0;
              line-height: 30px;
            ">
            例如可实时接收审核状态变更提醒、管理员定向提醒通知等。
          </p>
          <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/gongzhonghao.jpeg" style="width: 200px; height: 200px; margin: -5px auto -5px" />
        </template>
        <!-- 自定义提交文字 -->
        <template v-else>
          <div class="customTipContent" v-html="customTip.commitTipContent"></div>
        </template>

        <template v-if="formDetail.pay">
          <p style="
              width: 100%;
              text-align: center;
              font-size: 14px;
              margin: 10px auto 20px;
              line-height: 20px;
            ">
            系统查询到本表单已开启支付功能。选择“立即支付”，进入支付页面。选择“暂不支付”，后续可在填报列表“支付”。
          </p>
          <div class="pay-footer" style="
              display: flex;
              display: -webkit-flex;
              justify-content: space-around;
            ">
            <el-button @click="onCloseFillSuccessPop">暂不支付</el-button>
            <el-button type="primary" @click="onConfirmPayFillClick">立即支付</el-button>
          </div>
        </template>
      </div>
    </el-dialog>
    <!--填报成功，公众号引导提示-->

    <template v-if="isH5">
      <div style="width: 0px; height: 0px; overflow: hidden">
        <iframe ref="messageProxy" :src="postMessageProxy"></iframe>
      </div>
    </template>

    <vFormInsideViewer ref="dlgvFormViewer" v-if="ready"></vFormInsideViewer>
    <!-- <VFormViewer ref="dlgvFormViewer"></VFormViewer> -->
    <fillSelector ref="fillSelector" :optionData="optionData" v-if="ready"></fillSelector>
    <userSelect v-if="ready && userInfo" ref="userSelect" :callback="userSelectCallback" :formDetail="formDetail"></userSelect>
    <VFormViewer ref="dlgvNewFormViewer" v-if="showAddNew" :quite="true" :formAuth="formAuth" @on-save="onHandleDataChooseAddData"></VFormViewer>

    <table-subform ref="table_subform"></table-subform>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import {
  fill,
  formDetail,
  formAuth,
  fillDetail,
  editFill,
  httpGetFormFieldV2,
  getCustomTip,
  formPayInfo,
  deleleteFile,
  getFormFieldAuth,
} from "@/api/system/form";

import {
  saveDraft,
  getDraft,
  submitDraft,
  deleteDraft,
  updateDraft,
} from "@/api/system/draft";

import { viewDetail } from "@/api/system/views.js";

import { getUserInfo } from "@/api/system/user";
import { showPicturePreview, changedValue } from "@/utils/formUtil.js";
// import VFormViewer from './components/vFormViewer/indexH5.vue'
import fillSelector from "./components/fillSelector/index.vue";

import { getAgency, getAgencyUser, agencyUserList } from "@/api/system/agency";
import { aliUploader } from "@/utils/oss";
import { getSessionStorageObj, setSessionStorageObj } from "@/utils/db";
import gridConfig from "@/utils/grid.js";

import vFormInsideViewer from "@/views/system/form/components/vFormViewer/inside.vue";
import userSelect from "@/views/system/mechan/components/user-select.vue";
import FillSelectTableView from "@/views/system/form/components/fillSelector/tableView";
import VFormViewer from "@/views/system/form/components/vFormViewer/index";
import tableSubform from '@/views/system/form/components/tables/table_extend_subform';

import saveDataChooseMixin from "./mixins/saveDataChooseMixin";
import { getAuditLevelByStatus } from "@/utils/formUtil";
export default {
  mixins: [saveDataChooseMixin],
  componentName: 'h5Render',
  props: {
    msg: String,
  },
  components: {
    fillSelector,
    vFormInsideViewer,
    userSelect,
    FillSelectTableView,
    VFormViewer,
    tableSubform
  },
  created () {
    const _this = this;
    const token = this.$route.params.token || this.$route.query.token;
    this.token = token;
    Cookies.set("User-Token", token);
    this.formId = this.$route.params.id || this.$route.query.formId;
    this.optionData = {
      userToken: token,
      apiUrl: `${process.env.VUE_APP_BASE_API_URL}`,
      formId: this.formId,
      formPayInfo: this.getFormPayInfo,
    };

    this.optionData.formDataSelectHandler = this.showDataSelect;
    this.optionData.formDataSelectAutoFillHandler = this.showDataAutoSelect;
    this.optionData.formDataSelectMultiFillHandler = this.showDataMultiSelect;
    this.optionData.formDataViewHandler = this.showDataView;
    this.optionData.formDataCardRender = this.dataCardRender();
    this.optionData.formPicturePreviewHandler = showPicturePreview;
    this.optionData.userSelectHandler = this.showUserSelect;
    this.optionData.loadAgencyUserExtendFieldsHandler = getAgency;
    this.optionData.loadAgencyUserListHandler = agencyUserList
    this.optionData.loadAgencyUserHandler = (agencyId) => {
      return new Promise((resolve, reject) => {
        getAgencyUser(this.formDetail.agencyId).then((res) => {
          resolve(res);
        });
      });
    };
    this.optionData.fileViewUrl = process.env.VUE_APP_BASE_FILE_PREVIEW;

    this.formAgreementId =
      this.$route.params.agreeId || this.$route.query.agreeId;

    this.userInfo = getSessionStorageObj("users");

    if (!this.optionData.aliOSSUploadConfig) {
      this.optionData.aliOSSUploadConfig = {};
    }
  },
  mounted () {
    const _this = this;
    this.formId = this.$route.params.id || this.$route.query.formId;
    this.fillId = this.$route.query.fillId;
    this.viewId = this.$route.query.viewId;
    this.draftId = this.$route.query.draftFillId;

    this.ver = this.$route.query.ver || 1;
    this.type = this.$route.query.type || 2;
    this.showHidden = this.$route.query.showHidden == "true";
    this.redirect = this.$route.query.redirect;
    this.formAgreementId = this.$route.query.agreeId;
    this.enableReadonly = this.$route.query.enableReadonly;
    this.isH5 = this.$route.query.h5 == "true";
    // formModel new 新增 audit 审核 view查看 edit编辑 editDraft编辑草稿 signField多人签名表单-仅显示签名字段    this.formMode = this.$route.query.formMode || "view";
    this.formMode = this.$route.query.formMode || "view";
    this.listType = this.$route.query.listType || "FILL";
    this.auditLevel = this.$route.query.auditLevel || 0;
    this.isMiniProgram =
      window.navigator.userAgent.toLocaleLowerCase().indexOf("miniprogram") >
      -1;
    console.log(this.isMiniProgram ? "微信小程序中运行" : "非微信小程序运行");
    if (this.isH5) {
      this.postMessageProxy = decodeURIComponent(this.$route.query.proxy);
    }
    console.log("this.formId", this.formId);
    Promise.all([
      formDetail(this.formId),
      this.getCustomTip(this.formId),
      httpGetFormFieldV2(this.formId),
      this.fillDetail(this.fillId, this.formId),
      formAuth(this.formId),
      this.getUserInfo(),
      this.getViewInfo(),
    ])
      .then(
        ([
          formDetailRes,
          res2,
          formJsonRes,
          fillDataRes,
          fillAuthRes,
          userInfoRes,
        ]) => {
          // 表单基本设置
          this.formDetail = formDetailRes.data;
          this.formData = fillDataRes.data;
          this.formAuth = fillAuthRes.data;

          this.fillInfo = {
            fillUserName: "",
            fillUserAvatarUrl: "",
            fillUserId: this.formData.userId,
            createTime: this.formData.createTime,
            updateTime: this.formData.updateTime,
          };

          // 用v-if ready确保 加载表单在获取了用户信息之后
          this.ready = true;
          // 表单配置
          this.formJson = formJsonRes.data;

          // 判断表单不能填报，自动返回
          const notAllowEditAndReturnBack = () => {
            this.$message({
              message: "当前填报禁止修改",
              center: true,
              duration: 2000,
              onClose: () => {
                const redirectData = {
                  data: {
                    redirect: this.redirect,
                  },
                };

                let redirectUrl =
                  "/pages/form/fill/fillv2/postMessageProxy?aciton=return";
                if (this.isMiniProgram) {
                  // wx.miniProgram.postMessage(redirectData);
                  // wx.miniProgram.navigateBack();
                  wx.miniProgram.redirectTo({ url: redirectUrl });
                } else {
                  try {
                    // uni.postMessage(redirectData);
                    uni.redirectTo({ url: redirectUrl });
                  } catch {
                    window.location.replace(redirectUrl);
                  }
                }
              },
            });
          };
          // 如果表单开启了审核，需要判断修改条件
          // 当前用户不为管理员时：不允许修改：
          // 1：审核完成
          // 2: 一级审核通过，一级审核驳回，2级审核通过，2级审核驳回，3级审核通过，3级审核驳回。

          if (this.formMode === "edit") {
            if (
              !this.isDataManager &&
              !this.isFormSubAdmin &&
              !this.isFormCreater
            ) {
              if (
                !(
                  this.formData["status"] == 1 ||
                  this.formData["status"] == 2 ||
                  this.formData["status"] == 5 ||
                  this.formData["status"] == 9 ||
                  this.formData["status"] == 13
                )
              ) {
                // 不允许填报
                notAllowEditAndReturnBack();
                return;
              }

              // 表单到期不允许修改
              if (this.disableEditFill) {
                // 不允许填报
                notAllowEditAndReturnBack();
                return;
              }
            }
          }

          if (!this.optionData.aliOSSUploadConfig) {
            this.optionData.aliOSSUploadConfig = {};
          }
          this.optionData.formDetail = this.formDetail;
          this.optionData.aliOSSUpload = aliUploader;
          this.optionData.formAgencyId = this.formDetail.agencyId;
          this.optionData.aliOSSUploadConfig.formId = this.formId || "formId";
          this.optionData.aliOSSUploadConfig.agencyId =
            this.formDetail.agencyId;
          this.optionData.aliOSSUploadConfig.uploadPath =
            "fillFile/{agencyId}/{formId}/{userId}/{fieldName}/";
          this.optionData.fileDelete = this.fileDelete;
          this.optionData.formData = {};
          this.optionData.fillId = this.fillId;
          this.optionData.fillInfo = this.fillInfo;
          this.optionData.listType = this.listType;
          this.optionData.showAddNew = this.showAddNewDialog;
          this.optionData.showEdit = this.showEditDialog;

          this.$nextTick(() => {
            this.$refs.vformDesigner.setFormJson(_this.formJson, {
              formMode: this.formMode,
              listType: this.listType,
            });

            this.$nextTick(() => {
              // this.draftId = localStorage.getItem("draftId");
              // this.draftFormId = localStorage.getItem("draftFormId");
              // this.draft = JSON.parse(localStorage.getItem("draft"));

              //type 0 新增 1 编辑 2查看
              if (_this.type > 0) {
                // 编辑或查看
                // 读取表单数据

                this.$set(this.optionData, "formData", this.formData);

                _this.$nextTick(() => {
                  // setFormAdnWidgets： 设置禁用/只读表单字段
                  // 先把该隐藏的字段隐藏，但是设置上数据之后，子表单会重新显示/可编辑，所以再执行一次
                  _this.setWidgetsDisableOrHidden().then(() => {
                    try {
                      _this.optionData.formData = {
                        ...this.formData,
                        ...this.formData.fillJson,
                      };
                    } catch (e) {
                      this.$message(e.message);
                    }
                    _this.$refs.vformDesigner.setFormData(
                      this.formData.fillJson,
                      _this.formId,
                      _this.fillId
                    );

                    _this.$nextTick(() => {
                      _this.setWidgetsDisableOrHidden();

                      //禁止支付相关的字段的编辑
                      if (
                        _this.formDetail.pay &&
                        fillDataRes.data.payStatus > 2 &&
                        _this.optionData.formPayInfo
                      ) {
                        _this.optionData.formPayInfo().then((payInfoData) => {
                          _this.$refs.vformDesigner.disableWidgets([
                            payInfoData.data.formFieldId,
                          ]);
                        });
                      }
                    });
                  });
                });
              } else {
                _this.$refs.vformDesigner
                  .getNativeRender()
                  .setFormId(this.formId);
                _this.$nextTick(() => {
                  _this.setWidgetsDisableOrHidden();
                });
              }

              // 如果是新增的表单需要手动触发逻辑显示字段执行
              if (this.formMode == "new") {
                _this.$refs.vformDesigner.updateLogicalWidgets();
              }
              if (this.formMode == "editDraft") {
                getDraft(_this.formId, _this.draftId).then((res) => {
                  _this.$refs.vformDesigner.setFormData(res.data.fillJson);
                });
              }
              if (
                this.formMode == "new" ||
                this.formMode == "edit" ||
                this.formMode == "editDraft"
              ) {
                if (this.$refs.vformDesigner.showSubmitHandler) {
                  this.$refs.vformDesigner.showSubmitHandler();
                }
              }
              setTimeout(() => {
                this.$refs.vformDesigner.enableDataEditEvent();
              }, 1000);
            });
          });
        }
      )
      .catch((e) => {
        console.error("e", e.message);
        this.$message.error(e.message);
      });
  },
  data () {
    return {
      formId: "",
      viewId: "",
      fillId: "",
      type: "0", // 0 新增 1编辑 2 查看
      formJson: {},
      formData: {},
      formAuth: {},
      formDataCopy: {},
      formDetail: {},
      viewFields: {},
      viewHideFields: {},
      viewShowFields: {},
      viewReadonlyFields: {},

      loading: {
        submit: false,
        draft: false,
      },
      optionData: {
        aliOSSUploadConfig: {},
      },
      draft: {},
      draftId: "",
      draftFormId: "",
      formMode: "",
      listType: "",
      token: "",
      customTip: {},
      formAgreementId: null,
      ready: false,
      userSelectCallback: null,
      userInfo: null,
      isMiniProgram: false,
      isH5: false,
      postMessageProxy: "",
      fillSuccessShow: false,
      userAgency: {},
      showAddNew: false,
      addNewSaveCallbackHandler: null,
    };
  },
  watch: {
    userInfo: {
      deep: true,
      handler: function (val) {
        this.optionData.aliOSSUploadConfig.userId = this.userInfo.userId;
        this.optionData.isUserAdmin = this.userInfo.type > 1;
        this.optionData.formCreateUser = this.userInfo;
      },
    },
  },
  computed: {
    disableEditFill: function () {
      // console.log(!this.formDetail.isStop, this.formAuth.currentEditFillAuth)
      return (
        // 表单到期了并且不允许修改 返回true
        !this.formDetail.isStop ||
        (this.formAuth.currentEditFillAuth == true ? false : true)
      );
    },
    isFormCreater: function () {
      console.log(this.userInfo);
      return this.formDetail.userId === this.userInfo.userId;
    },
    // 判断是不是表单子管理员
    isFormSubAdmin: function () {
      if (this.formDetail.managerUserId && this.userInfo) {
        // 判断字符串是否包含this.userAgency.userId
        return (
          this.formDetail.managerUserId.indexOf(this.userInfo.userId) !== -1
        );
      } else {
        return false;
      }
    },
    isCreateUser: function () {
      return this.formData.currentIsCreateUser !== false;
    },
    isDataManager: function () {
      return this.formData.currentIsDataManager !== false;
    },
  },
  methods: {
    async setWidgetsDisableOrHidden () {
      let res;
      if (this.viewId) {
        res = await this.setViewWidgetsDisableOrHidden();
      } else {
        res = await this.setFormWidgetsDisableOrHidden();
      }
      return res;
    },

    // 设置表单禁用状态及字段状态,隐藏或禁用
    setFormWidgetsDisableOrHidden () {
      return new Promise((resolve) => {
        console.log(this.listType, "listType", this.formMode, "formMode");

        let allWidgetList = this.getFormFieldList(this.formJson.widgetList);
        let allWidgetnames = allWidgetList.map((x) => x.options.name);
        if (this.listType === "AUDIT") {
          getFormFieldAuth({
            formId: this.formId,
            level: this.auditLevel || this.getAuditLevel(),
            type: 1,
          }).then((res) => {
            let fieldAuth = [];
            let readonlyWidgets = [];
            let hiddenWidgets = [];

            let notReadonlyWidgets = [];
            let notHiddenWidgets = [];
            if (res.data && res.data.fieldAuth) {
              fieldAuth = res.data.fieldAuth;
            } else {
              let allWidgetList = this.getFormFieldList(
                this.formJson.widgetList
              );
              let allDisabledWidgetNames = allWidgetList
                .filter((x) => x.options.disabled)
                .map((x) => x.options.name);
              let allHiddenWidgetNames = allWidgetList
                .filter((x) => x.options.hidden)
                .map((x) => x.options.name);

              allDisabledWidgetNames.forEach((name) => {
                readonlyWidgets.push(name);
              });
              allHiddenWidgetNames.forEach((name) => {
                hiddenWidgets.push(name);
              });
            }
            Object.keys(fieldAuth).forEach((key) => {
              let val = fieldAuth[key];
              if (val.find((x) => x == 2)) {
                readonlyWidgets.push(key);
              } else {
                notReadonlyWidgets.push(key);
              }
              if (val.find((x) => x == 3)) {
                hiddenWidgets.push(key);
              } else {
                notHiddenWidgets.push(key);
              }
            });

            if (this.formMode == "view") {
              this.$refs.vformDesigner.disableForm();
            } else if (this.formMode == "signField") {
              this.$refs.vformDesigner.disableForm();
              this.$refs.vformDesigner.setNotReadonlyWidgets(
                [this.$route.query.fieldName],
                false
              );
            } else {
              this.$refs.vformDesigner.setReadonlyWidgets(
                readonlyWidgets,
                false
              );
              this.$refs.vformDesigner.setNotReadonlyWidgets(
                notReadonlyWidgets,
                false
              );
            }

            this.$refs.vformDesigner.setHiddenWidgets(hiddenWidgets);
            this.$refs.vformDesigner.setNotHiddenWidgets(notHiddenWidgets);
            this.$nextTick(() => {
              this.$refs.vformDesigner.updateLogicalWidgets(allWidgetnames);
            });
            resolve();
          });
        } else if (this.listType == "MANAGE") {
          // let allWidgetList = this.getFormFieldList(this.formJson.widgetList);
          // let allWidgetnames = allWidgetList.map((x) => x.options.name);

          this.$nextTick(() => {
            this.$refs.vformDesigner.setNotReadonlyWidgets(
              allWidgetnames,
              false
            );
            this.$refs.vformDesigner.setNotHiddenWidgets(allWidgetnames);
            if (this.formMode == "view") {
              this.$refs.vformDesigner.disableForm();
            } else if (this.formMode == "signField") {
              this.$refs.vformDesigner.disableForm();
              this.$refs.vformDesigner.setNotReadonlyWidgets(
                [this.$route.query.fieldName],
                false
              );
            }
            this.$nextTick(() => {
              this.$refs.vformDesigner.updateLogicalWidgets(allWidgetnames);
            });
          });
          resolve();
        } else {
          // 个人填报
          if (this.formMode == "new") {
          } else if (this.formMode == "signField") {
            this.$refs.vformDesigner.disableForm();
            this.$refs.vformDesigner.setNotReadonlyWidgets(
              [this.$route.query.fieldName],
              false
            );
          } else if (this.formMode == "view") {
            this.$refs.vformDesigner.disableForm();
          }

          resolve();
        }
      });
    },
    // 根据视图设置隐藏和显示的字段
    setViewWidgetsDisableOrHidden () {
      return new Promise((resolve) => {
        let allWidgetList = this.getFormFieldList(this.formJson.widgetList);
        let allWidgetnames = allWidgetList.map((x) => x.options.name);
        let dlgvFormViewer = this.$refs.vformDesigner;
        // 设置所有字段都可编辑
        dlgvFormViewer.setNotReadonlyWidgets(allWidgetnames, false);
        // 设置所有字段不可见
        dlgvFormViewer.setHiddenWidgets(allWidgetnames);

        if (this.viewShowFields && Array.isArray(this.viewShowFields)) {
          let showNames = [];
          allWidgetList.forEach((x) => {
            let findWgt = this.viewShowFields.find(
              (y) => y.name === x.options.name
            );
            if (findWgt) {
              showNames.push(x.options.name);
              if (x.widgetList) {
                showNames = showNames.concat(
                  x.widgetList.map((t) => t.options.name)
                );
              }
            }
          });
          dlgvFormViewer.setNotHiddenWidgets(showNames);
        }

        if (this.viewReadonlyFields && Array.isArray(this.viewReadonlyFields)) {
          const disableFields = this.viewReadonlyFields.map((x) => x.name);
          dlgvFormViewer.setReadonlyWidgets(disableFields, true);
        }
        this.$nextTick(() => {
          // 重新触发逻辑显示
          // dlgvFormViewer.updateLogicalWidgets();
        });

        resolve();
      });
    },
    getFormFieldList (widgetList) {
      let allWidgetList = [];

      widgetList.forEach((wgt) => {
        allWidgetList.push(wgt);

        if (wgt.widgetList) {
          allWidgetList = allWidgetList.concat(
            this.getFormFieldList(wgt.widgetList)
          );
        }
      });
      return allWidgetList;
    },
    saveFormData (data) {
      const _this = this;
      const param = {
        formId: _this.formId,
        fillJson: data,
        formAgreementId: _this.formAgreementId,
        formAgreementSignature: "",
      };

      this.formDataCopy = JSON.parse(JSON.stringify(data));

      if (this.type == "0" && !this.$refs.vformDesigner.isFormDataEdit()) {
        _this.msgError("请填写完表单信息后再提交");
        return;
      }

      this.loading.submit = true;

      setTimeout(() => {
        this.loading.submit = false;
      }, 20 * 1000);

      if (this.formMode == "data") {
        this.$emit("on-save", param);
        this.isShow = false;
        return;
      }
      // if (this.formMode == "editDraft") {
      // updateDraft({
      //   formId: this.formId,
      //   fillId: this.draftId,
      //   fillJson: data,
      //   fillChange: [],
      // }).then(() => {
      //   submitDraft(this.formId, this.draftId)
      //     .then(async (newRes) => {
      //       _this.fillSuccessShow = true;
      //       _this.fillId = newRes.data;
      //       _this.formSubmitFillId = response.data;
      //       await this.addDataChoose(
      //         param,
      //         _this.formId,
      //         _this.formSubmitFillId
      //       );
      //       _this.clearDraft();
      //       _this.$forceUpdate();
      //       _this.loading.submit = false;
      //       _this.$emit("on-save", _this.formId, _this.fillId);
      //     })
      //     .catch(() => {
      //       _this.loading.submit = false;
      //     });
      // });

      // return;
      // }

      if (this.type == "0" || this.formMode == "editDraft") {
        //填报模式
        fill(param)
          .then(async (response) => {
            if (response.code == 200) {
              // _this.msgSuccess('填报信息保存成功')
              _this.fillSuccessShow = true;
              _this.fillId = response.data;
              _this.formSubmitFillId = response.data;
              await _this.addRelativeLink(_this.formSubmitFillId);
              // await this.addDataChoose(
              //   param,
              //   _this.formId,
              //   _this.formSubmitFillId
              // );

              // setTimeout(() => {
              //   _this.fillSuccessShow = false
              // }, 3000)
              _this.clearDraft();
              _this.$forceUpdate();
              _this.loading.submit = false;
              if (_this.formMode == "editDraft") {
                deleteDraft(_this.formId, _this.draftId).then(() => {
                  // _this.$emit("on-save", _this.formId, _this.fillId);
                  _this.fillSuccessShow = true;
                });
              } else {
                _this.fillSuccessShow = true;
                // _this.$emit("on-save", _this.formId, _this.fillId);
              }
              // _this.pageOnSave(data)
            }
          })
          .catch(() => {
            _this.loading.submit = false;
          });
      } else if (this.type == "1") {
        //编辑模式
        this.editDataChoose(data, this.formId, this.fillId).then(() => {
          editFill({
            fillId: _this.fillId,
            formId: _this.formId,
            fillJson: data,
            fillChange: changedValue(this.formJson, this.formData, data),
          })
            .then(async (response) => {
              if (response.code == 200) {
                _this.msgSuccess("提交成功");

                await _this.addRelativeLink(_this.fillId);
                // _this.fillSuccessShow = true;
                // setTimeout(() => {
                //   _this.fillSuccessShow = false
                // }, 3000)
                _this.clearDraft();
                _this.loading.submit = false;
                // _this.$forceUpdate();
                // _this.$emit("on-save", data);
                // _this.pageOnSave(data)
                setTimeout(() => {
                  _this.onCloseFillSuccessPop();
                }, 1000);
              }
            })
            .catch(() => {
              _this.loading.submit = false;
            });
        });
      }
    },
    addRelativeLink (fillId) {
      const fieldWidgets = this.$refs.vformDesigner
        .getNativeRender()
        .getFieldWidgets();
      const relativeWidgets = fieldWidgets.filter(
        (x) =>
          x.field.type == "fill-select" ||
          x.field.type === "fill-select" ||
          (x.field.subtype &&
            x.field.subtype == "relative" &&
            !(
              x.field.type === "sub-form" &&
              x.field.options.reference === "copy"
            ))
        // 因为考虑历史数据里都是copy,所以暂时允许非 子表单的copy(复制)模式的字段以link(引用)模式运行
      );

      return new Promise((resolve, reject) => {
        relativeWidgets.forEach(async (x) => {
          const widget = this.$refs.vformDesigner
            .getNativeRender()
            .getWidgetRef(x.field.options.name);
          if (widget.syncRelativeChange) {
            await widget.syncRelativeChange(fillId);
          }
        });
        resolve();
      });
    },
    saveFormDataError (errMsg) {
      this.msgError(errMsg);
    },
    // 渲染表头
    renderTableColumns (formJsonFieldList, formDetail, hideHiddenField) {
      const _this = this;
      let columns = [];
      gridConfig
        .renderHeaderColumns(
          formJsonFieldList,
          {
            formDetail: formDetail,
            dataFillStatus: false,
          },
          { hideHiddenField: hideHiddenField }
        )
        .forEach((c) => {
          columns.push(c);
        });
      return columns;
    },
    // pageOnSave(data) {
    //   const _this = this;
    //   setTimeout(() => {
    //     const redirectData = {
    //       data: {
    //         redirect: this.redirect,
    //       },
    //     };

    //     if (this.isMiniProgram) {
    //       wx.miniProgram.postMessage(redirectData);
    //       // wx.miniProgram.navigateBack();
    //       wx.miniProgram.navigateTo({
    //         url:'/pages/form/fill/fillv2/postMessageProxy'
    //       });
    //     } else {
    //       try {
    //         uni.postMessage(redirectData);
    //         uni.navigateBack();
    //       } catch {
    //         this.$refs.messageProxy.contentWindow.postMessage(
    //           JSON.stringify(redirectData),
    //           "*"
    //         );
    //       }
    //     }
    //   }, 3000);
    // },
    formDataChanged (data) {
      this.isEdit = true;
      localStorage.setItem("draft", JSON.stringify(data));
      localStorage.setItem("draftId", this.fillId || "");
      localStorage.setItem("draftFormId", this.formId);
    },
    getCustomTip () {
      const _this = this;
      return new Promise((resolve, reject) => {
        // 获取表单配置(表单设计器)数据
        getCustomTip(_this.formId).then((response) => {
          if (response) {
            this.customTip = response.data;
            resolve();
          } else {
            resolve({ data: {} })
          }
        });
      });
    },
    clearDraft () {
      localStorage.removeItem("draft");
      localStorage.removeItem("draftId");
      localStorage.removeItem("draftFormId");
    },
    onCloseFillSuccessPop () {
      this.fillSuccessShow = false;
      setTimeout(() => {
        const redirectData = {
          data: {
            redirect: this.redirect,
          },
        };

        let redirectUrl = `/pages/form/fill/fillv2/postMessageProxy?action=submit&redirect=${this.redirect}&formId=${this.formId}&fillId=${this.fillId}`;
        if (this.isMiniProgram) {
          // wx.miniProgram.postMessage(redirectData);
          // 因为增加了弹出提示，会阻止navigateBack(), 只能跳转到下一个页面，并且迅速关闭才能收到事件
          // wx.miniProgram.navigateBack();
          wx.miniProgram.redirectTo({ url: redirectUrl });
        } else if (!this.isH5) {
          // uni.postMessage(redirectData);
          uni.redirectTo({ url: redirectUrl });
        } else {
          window.location.replace(redirectUrl);
        }
      }, 500);
    },
    showDataSelect (fillSelectOption, callback) {
      // callback:选择完成后的回调
      this.$refs.fillSelector.show(fillSelectOption, callback);
    },
    showDataAutoSelect (fillSelectOption, callback) {
      // callback:选择完成后的回调
      this.$refs.fillSelector.autoFill(fillSelectOption, callback);
    },
    showDataMultiSelect (fillSelectOption, callback) {
      fillSelectOption.formId = this.formId;
      this.$refs.fillSelector.autoFillMulti(fillSelectOption, callback);
    },
    async showDataView (fillSelectOption, fillData, callback) {
      // callback:选择完成后的回调
      // 加载表单配置数据

      const res = await httpGetFormFieldV2(fillSelectOption.formId);

      // 同时加载，提升加载速度
      const loadField = new Promise((resolve, reject) => {
        httpGetFormFieldV2(fillSelectOption.formId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
      const loadData = new Promise((resolve, reject) => {
        fillDetail(fillData.fillId, fillSelectOption.formId)
          .then((res) => {
            resolve(res.data.fillJson);
          })
          .catch((err) => {
            reject(err);
          });
      });

      Promise.all([loadField, loadData]).then((values) => {
        this.$refs.dlgvFormViewer.showView({
          fillId: fillData.fillId,
          formId: fillSelectOption.formId,
          formJson: values[0],
          formData: values[1],
          showHiddenWidgets: this.cur != 3,
          showFields: null,
          formDetail: {},
          callback: () => {
            this.$refs.dlgvFormViewer.disableForm();
          },
        });
      });
    },
    // 引用table的render，显示dataCard内容
    dataCardRender () {
      const renders = require("@/utils/gridrender/cellRender");
      return renders.renderProvider;
    },
    //获取支付金额接口
    async getFormPayInfo (callback) {
      // if (this.formDetail.pay == true) {
      //   return {}
      // }
      return formPayInfo(this.formId);
    },
    fillDetail (fillId, formId) {
      return new Promise((resolve, reject) => {
        if (!fillId) {
          resolve({ data: {} });
          return;
        }
        fillDetail(fillId, formId).then((res) => {
          resolve(res);
        });
      });
    },
    onConfirmPayFillClick () {
      setTimeout(() => {
        const redirectData = {
          data: {
            action: "pay",
            redirect: `payConfirm`,
            formId: this.formId,
            fillId: this.fillId,
            amount: this.formDataCopy.__amount
              ? Number(this.formDataCopy.__amount || "1")
              : undefined,
          },
        };
        if (this.isMiniProgram) {
          wx.miniProgram.postMessage(redirectData);
          // wx.miniProgram.navigateBack();

          //redirectData.data 整理成query参数
          const query = Object.keys(redirectData.data).reduce((result, key) => {
            result.push(`${key}=${redirectData.data[key]}`);
            return result;
          }, []);

          wx.miniProgram.redirectTo({
            url: `/pages/form/fill/fillv2/postMessageProxy?${query.join("&")}`,
          });
        } else {
          try {
            uni.postMessage(redirectData);
            uni.navigateBack();
          } catch {
            this.$refs.messageProxy.contentWindow.postMessage(
              JSON.stringify(redirectData),
              "*"
            );
          }
        }
      }, 100);

      return;
      console.log(WeixinJSBridge, "WeixinJSBridge");
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: "", //公众号名称，由商户传入
          timeStamp: "", //时间戳，自1970年以来的秒数
          nonceStr: "", //随机串
          package: "",
          signType: "", //微信签名方式：
          paySign: "", //微信签名
        },
        function (res) {
          console.log("pay callback");
        }
      );
    },
    showUserSelect (selectedUserList, fieldOptions, callback) {
      this.$refs.userSelect.showDialog(selectedUserList, fieldOptions);
      this.userSelectCallback = callback;
    },

    fileDelete (file) {
      deleleteFile(file.fileId).then((res) => { });
      if (file.thumbFileId) {
        deleleteFile(file.thumbFileId).then((res) => { });
      }
    },
    getAuditLevel () {
      const state = Number(this.formDetail["status"]);
      
      var level = getAuditLevelByStatus(state);
      return level;
    },
    getUserInfo () {
      return new Promise((resolve) => {
        if (!this.token) {
          resolve();
          return;
        }
        if (!this.userInfo) {
          getUserInfo()
            .then((res) => {
              setSessionStorageObj("users", res.data);
              this.userInfo = res.data;

              this.optionData.aliOSSUploadConfig.userId = this.userInfo.userId;
              this.optionData.isUserAdmin = this.userInfo.type > 1;
              this.optionData.formCreateUser = this.userInfo;
              resolve();
            })
            .catch(() => { });
        } else {
          this.optionData.aliOSSUploadConfig.userId = this.userInfo.userId;
          this.optionData.isUserAdmin = this.userInfo.type > 1;
          this.optionData.formCreateUser = this.userInfo;
          resolve();
        }
      });
    },
    getViewInfo () {
      if (this.viewId == "" || !this.viewId) {
        return new Promise((resolve) => {
          resolve();
        });
      } else {
        return new Promise(async (resolve) => {
          let viewDetailRes = await viewDetail(this.viewId);

          let viewFields = viewDetailRes.data.showFields;
          this.viewFields = viewFields
            .filter((x) => !x.hide)
            .map((x) => {
              return {
                ...x,
                name: x.field,
              };
            });
          this.viewHideFields = viewFields
            .filter((x) => x.hide || x.hide == undefined)
            .map((x) => {
              return {
                ...x,
                name: x.field,
              };
            });
          this.viewShowFields = viewFields
            .filter((x) => !x.hide || x.hide == undefined)
            .map((x) => {
              return {
                ...x,
                name: x.field,
              };
            });
          this.viewReadonlyFields = viewFields
            .filter((x) => x.editable == false || x.editable == undefined)
            .map((x) => {
              return {
                ...x,
                name: x.field,
              };
            });

          resolve();
        });
      }
    },
    showAddNewDialog (param, callback) {
      this.addNewSaveCallbackHandler = callback;

      let formId = param.options.from;
      Promise.all([
        formDetail(formId),
        httpGetFormFieldV2(formId),
        formAuth(formId),
      ]).then(([formDetailRes, formJsonRes, formAuthRes]) => {
        let formAuth = formAuthRes.data;
        let formDetail = formDetailRes.data;
        let formJson = formJsonRes.data;

        // isStop 是反的，true的时候没有stop，false的时候是stop
        let isStop = !formDetail.isStop;
        if (!isStop && formAuth.currentInsertFillAuth === true) {
          this.showAddNew = true;
          this.$nextTick(() => {
            this.$refs.dlgvNewFormViewer.showAdd({
              title: "新增数据",
              formDetail,
              formData: {},
              formJson,
              formId,
              formMode: "data",
              listType: "FILL",
              callback: () => {
                // 回调 隐藏对应的字段
                this.$refs.dlgvNewFormViewer.setHiddenWidgets(
                  param.options.bothRelativeField
                );
              },
            });
          });
        } else {
          this.$message.error("表单已停止填报，或您没有填报权限");
        }
      });
    },
    showEditDialog (param, row, callback) {
      this.addNewSaveCallbackHandler = callback;

      let formId = param.options.from;
      Promise.all([
        formDetail(formId),
        httpGetFormFieldV2(formId),
        formAuth(formId),
      ]).then(([formDetailRes, formJsonRes, formAuthRes]) => {
        let formAuth = formAuthRes.data;
        let formDetail = formDetailRes.data;
        let formJson = formJsonRes.data;

        // isStop 是反的，true的时候没有stop，false的时候是stop
        let isStop = !formDetail.isStop;
        if (!isStop && formAuth.currentInsertFillAuth === true) {
          this.showAddNew = true;
          this.$nextTick(() => {
            this.$refs.dlgvNewFormViewer.showAdd({
              title: "编辑数据",
              formDetail,
              formData: {},
              formJson,
              formId,
              formMode: "edit",
              listType: "FILL",
              callback: () => {
                // 回调 隐藏对应的字段
                this.$refs.dlgvNewFormViewer.setFormData(row);
                this.$refs.dlgvNewFormViewer.setHiddenWidgets(
                  param.options.bothRelativeField
                );
              },
            });
          });
        } else {
          this.$message.error("表单已停止填报，或您没有填报权限");
        }
      });
    },
    onHandleDataChooseAddData (formData) {
      // fillDetail(fillId, formId).then((newData) => {
      if (this.addNewSaveCallbackHandler) {
        this.addNewSaveCallbackHandler(formData);
      }
    },
    saveDraft (fillJson) {
      if (!this.draftId) {
        // 保存好之后后退
        this.redirect = "to_draft";
        // 新增草稿
        saveDraft({
          formId: this.formId,
          fillJson,
          draft: true,
        }).then((res) => {
          this.saveAfterDraftBack();
        });
      } else {
        // 保存好之后后退更新草稿列表
        this.redirect = "to_draft";
        try {
          updateDraft({
            formId: this.formId,
            fillId: this.draftId,
            fillJson,
            fillChange: [],
          }).then(() => {
            this.saveAfterDraftBack();
          });
        } catch (e) { }
      }
    },
    saveAfterDraftBack () {
      const redirectData = {
        data: {
          redirect: this.redirect,
        },
      };
      if (this.isMiniProgram) {
        wx.miniProgram.postMessage(redirectData);
        wx.miniProgram.redirectTo({
          url: `/pages/form/fill/fillv2/postMessageProxy?action=draft&formId=${this.formId}`,
        });
      } else {
        try {
          uni.postMessage(redirectData);
          uni.navigateBack();
        } catch {
          this.$refs.messageProxy.contentWindow.postMessage(
            `/pages/form/fill/fillv2/postMessageProxy?action=draft&formId=${this.formId}`,
            "*"
          );
          setTimeout(() => {
            history.back();
          }, 100);
        }
      }
    },
    showSubform (field, subformData) {
      const columnConfig = this.formJson.fieldList.find((x) => x.name == field);
      
      columnConfig.params = {
        showRowNumber: columnConfig.showRowNumber,
        isShowTitleRow: columnConfig.isShowTitleRow,
        subWidgets: columnConfig.widgetList
      }

      this.$refs.table_subform.showSubformDialog({
        subformData, column: columnConfig, rowTitleNameLeft: this.rowTitleNameLeft
      })
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.customTipContent {
  max-height: 230px;
  overflow-y: auto;
  overflow-x: hidden;
  ::v-deep img {
    max-width: 300px;
  }
}

::v-deep .el-checkbox {
  margin-bottom: 5px;
}
::v-deep .el-checkbox__label {
  vertical-align: middle;
}
::v-deep .el-tag--small {
  word-break: break-all;
  white-space: normal;
  height: initial !important;
}

.h5Render {
  padding: 10px;
  box-sizing: border-box;
  background-color: white;
}

::v-deep .el-dialog__wrapper {
  border-radius: 6px;
  padding: 5px !important;
}
</style>
<style>
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101f1c, 0.1);
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  border-radius: 1em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.5);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  border-radius: 1em;
  transition: background-color 0.3s;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
