var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h5Render" },
    [
      _vm.ready
        ? _c("v-form-render-h5", {
            ref: "vformDesigner",
            attrs: {
              "form-json": {},
              "form-data": {},
              loading: _vm.loading,
              "option-data": _vm.optionData,
            },
            on: {
              onSaveDraft: _vm.saveDraft,
              onFormData: _vm.saveFormData,
              onFormDataError: _vm.saveFormDataError,
              onFormDataChanged: _vm.formDataChanged,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "myVxeTable",
                  fn: function (ref) {
                    var fields = ref.fields
                    var datas = ref.datas
                    var isAdmin = ref.isAdmin
                    var field = ref.field
                    var fieldRef = ref.fieldRef
                    var viewItem = ref.viewItem
                    var removeItem = ref.removeItem
                    var disabled = ref.disabled
                    var enableSearch = ref.enableSearch
                    if (enableSearch === void 0) enableSearch = true
                    var enableFilter = ref.enableFilter
                    if (enableFilter === void 0) enableFilter = true
                    return [
                      _c("fill-select-table-view", {
                        attrs: {
                          fields: fields,
                          datas: datas,
                          field: field,
                          fieldRef: fieldRef,
                          isAdmin: isAdmin,
                          viewItem: viewItem,
                          removeItem: removeItem,
                          onHandlePreviewImg: _vm.onHandlePreviewImg,
                          formOptionData: _vm.optionData,
                          disabled: disabled,
                          enableSearch: enableSearch,
                          enableFilter: enableFilter,
                          formDetail: _vm.formJson,
                          formData: _vm.formData,
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              4058731446
            ),
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.fillSuccessShow,
            width: "95%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fillSuccessShow = $event
            },
            close: _vm.onCloseFillSuccessPop,
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "-webkit-flex",
                "flex-direction": "column",
              },
            },
            [
              _c(
                "h1",
                {
                  staticStyle: {
                    display: "-webkit-flex",
                    "justify-content": "center",
                    "align-items": "center",
                    "font-size": "20px",
                    margin: "-20px 0 0",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-check",
                    staticStyle: {
                      color: "#67c23a",
                      "font-size": "40px",
                      "margin-right": "10px",
                    },
                  }),
                  _vm._v("提交成功！ "),
                ]
              ),
              _vm.customTip.commitTipShow == 0
                ? [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "left",
                          "font-size": "14px",
                          margin: "0",
                          "line-height": "30px",
                        },
                      },
                      [
                        _vm._v(
                          " 若希望及时接受相关通知，可关注我们的公众号“鲸鱼填报” "
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "left",
                          "font-size": "14px",
                          margin: "0",
                          "line-height": "30px",
                        },
                      },
                      [
                        _vm._v(
                          " 例如可实时接收审核状态变更提醒、管理员定向提醒通知等。 "
                        ),
                      ]
                    ),
                    _c("img", {
                      staticStyle: {
                        width: "200px",
                        height: "200px",
                        margin: "-5px auto -5px",
                      },
                      attrs: {
                        src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/gongzhonghao.jpeg",
                      },
                    }),
                  ]
                : [
                    _c("div", {
                      staticClass: "customTipContent",
                      domProps: {
                        innerHTML: _vm._s(_vm.customTip.commitTipContent),
                      },
                    }),
                  ],
              _vm.formDetail.pay
                ? [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "14px",
                          margin: "10px auto 20px",
                          "line-height": "20px",
                        },
                      },
                      [
                        _vm._v(
                          " 系统查询到本表单已开启支付功能。选择“立即支付”，进入支付页面。选择“暂不支付”，后续可在填报列表“支付”。 "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "pay-footer",
                        staticStyle: {
                          display: "-webkit-flex",
                          "justify-content": "space-around",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { on: { click: _vm.onCloseFillSuccessPop } },
                          [_vm._v("暂不支付")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onConfirmPayFillClick },
                          },
                          [_vm._v("立即支付")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm.isH5
        ? [
            _c(
              "div",
              {
                staticStyle: {
                  width: "0px",
                  height: "0px",
                  overflow: "hidden",
                },
              },
              [
                _c("iframe", {
                  ref: "messageProxy",
                  attrs: { src: _vm.postMessageProxy },
                }),
              ]
            ),
          ]
        : _vm._e(),
      _vm.ready ? _c("vFormInsideViewer", { ref: "dlgvFormViewer" }) : _vm._e(),
      _vm.ready
        ? _c("fillSelector", {
            ref: "fillSelector",
            attrs: { optionData: _vm.optionData },
          })
        : _vm._e(),
      _vm.ready && _vm.userInfo
        ? _c("userSelect", {
            ref: "userSelect",
            attrs: {
              callback: _vm.userSelectCallback,
              formDetail: _vm.formDetail,
            },
          })
        : _vm._e(),
      _vm.showAddNew
        ? _c("VFormViewer", {
            ref: "dlgvNewFormViewer",
            attrs: { quite: true, formAuth: _vm.formAuth },
            on: { "on-save": _vm.onHandleDataChooseAddData },
          })
        : _vm._e(),
      _c("table-subform", { ref: "table_subform" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }